@import '../../../global.less';

.toolbar-root {
  display: flex;
  justify-content: space-between;
  padding: @padding-xs 0;

  .toolbar-left {
    display: flex;
  }

  .toolbar-right {
    display: flex;
  }

  .toolbar-button {
    cursor: pointer;
    display: flex;
    height: 25px;
    width: 25px;
    font-size: 18px;
    color: @primary-color;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #dedede;
    }
  }

  .toolbar-divider {
    display: block;
    width: 0;
    height: 25px;
    border-left: 1px solid #dedede;
    margin: 0 3px;
  }
}
