@import '~antd/es/style/themes/default.less';

.subscribe-root {
  .ant-col {
    padding: 0 @padding-sm;

    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
  }
  
  .plan-container,
  .checkout-container {
    border: @border-width-base @border-style-base @border-color-base;
    padding: @padding-md;
    background-color: #fff;

    .row {
      display: flex;
      justify-content: space-between;
      border-top: @border-width-base @border-style-base @border-color-base;
      padding: @padding-sm 0;
    }
  }

}
