.no-speech-root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  h1 {
    margin-bottom: 0 !important;
  }

  h3 {
    margin-top: 0 !important;
  }

  ol {
    list-style: decimal;
  }
}
