@import '../../../global.less';

@yellow: rgb(233, 199, 123);

.note-list-root {
  min-width: 400px;

  .note-list-header {
    margin-bottom: @margin-md;
    font-weight: bold;
    text-transform: uppercase;

    &:after {
      display: block;
      content: ' ';
      width: 70px;
      height: 3px;
      background-color: @text-color;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: @margin-md;
      font-size: 12px;
      

      .note-timestamp {
        cursor: pointer;
        color: rgb(42, 147, 213);
        font-weight: bold;

        .note-icon {
          color: @yellow;
          width: 20px;
        }

        &:hover {
          background-color: rgb(42, 147, 213);
          color: #fff;
        }
      }

      .note-content {
        padding-left: 20px;
      }
    }
  }
}

