@import '~antd/es/style/themes/default.less';

.audio-player-container {
  padding: @padding-sm @padding-sm 0 0;

  audio {
    width: 100%;
  }
}

.transcription-container {
  padding: 0 @padding-sm;
  overflow-y: scroll;

  .trans-line {
    margin: @margin-md 0;
  }

  .trans-line-time {
    font-weight: bold;
  }
}
