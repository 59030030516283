@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './theme.less';

html,
body,
#root {
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: @header-font-family;
}

@media (max-width: @screen-xs) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
}

.root > .ant-layout {
  min-height: 100vh;
}

@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .root > .ant-layout {
    min-height: 100vh;
  }
}

.root .ant-layout-header.fixed-header {
  position: fixed;
  top: 0;
  z-index: 101;
}

.root .ant-layout-sider.fixed-sider {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  background-color: #fff;
}

.root .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.root .ant-layout-content {
  position: relative;
  margin: @margin-lg;
  margin-bottom: @margin-sm;
}

.root .ant-layout-footer {
  padding-top: 0;
  padding-bottom: @padding-sm;
}

@media (max-width: @screen-md) {
  .root .ant-layout-sider.fixed-sider {
    display: none;
  }
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}
