@import '~antd/es/style/themes/default.less';

.home-root {
  .home-top-section {
    display: flex;
    justify-content: space-between;
  }

  .home-file-list {
    margin-top: @margin-lg;
  }

  .ant-table-tbody {
    & > tr.ant-table-row-level-0:hover > td {
      cursor: pointer;
      // background: unset;
    }

    & > tr.row-selected > td,
    & > tr.ant-table-row-level-0.row-selected:hover > td {
      background: @background-color-base;
    }
  }
}
