.login-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(253, 253, 253);

  .login-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 350px;
    flex-grow: 1;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 0px #0000001a;
    padding: 32px 24px;
    box-sizing: border-box;

    & > img {
      width: 100%;
      max-width: 250px;
      padding: 20px;
      padding-bottom: 6px;
    }

    .firebaseui-idp-button {
      border-radius: 0;
    }
  }
}
