@import '~antd/es/style/themes/default.less';

.table-load-more {
  cursor: pointer;
  text-align: center;
}

.home-file-name {
  user-select: none;
}
