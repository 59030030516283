@import '../../../global.less';

.speaker-chooser-root {
  position: relative;

  .anticon {
    padding: 0 6px;
  }

  .speaker-chooser-current {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    padding: 2px 0;
    line-height: 24px;
    user-select: none;

    &:hover, 
    &.active {
      background-color: #fafafa;
      border: 1px solid #cecece;
    }

    .speaker-chooser-current-text {
      flex: 1;
    }
  }

  .speaker-chooser-dropdown {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    border: 1px solid #cecece;
    border-top: none;
    overflow: hidden;
    background: #fff;
    transition: height 200ms ease-in-out;
    z-index: 1001;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        line-height: 24px;

        .speaker-chooser-item-icon {
          padding: 8px 0;

          &:hover {
            background-color: #dfdfdf;
          }
        }

        .speaker-chooser-item-value {
          flex: 1;
          padding: 8px 6px;

          &:hover {
            background-color: #dfdfdf;
          }
        }
      }
    }
  }
}

