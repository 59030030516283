@import '~antd/es/style/themes/default.less';

.purchase-root {
  .ant-col {
    padding: 0 @padding-sm;

    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
  }

  .price-guide-container,
  .checkout-container {
    border: @border-width-base @border-style-base @border-color-base;
    padding: @padding-md;
    background-color: #fff;

    .row {
      display: flex;
      border-top: @border-width-base @border-style-base @border-color-base;
      padding: @padding-sm 0;

      .hour {
        flex: 1;
      }

      .price {
        flex: 1;
      }
    }

    .inputs {
      display: flex;
      padding: @padding-sm 0;

      .price {
        margin-left: @margin-sm;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
