@import '../../../global.less';

.confidence-root {
  min-width: 400px;

  .confidence-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: @margin-md;

    .confidence-overall {
      font-weight: bold;
      text-transform: uppercase;

      &:after {
        display: block;
        content: ' ';
        width: 70px;
        height: 3px;
        background-color: @text-color;
      }
    }

    .confidence-switch {
    }
  }

  .confidence-details-header {
    margin-bottom: @margin-md;
    font-weight: bold;
    text-transform: uppercase;

    &:after {
      display: block;
      content: ' ';
      width: 70px;
      height: 3px;
      background-color: @text-color;
    }
  }

  .confidence-details-chart {
    display: flex;
    width: 100%;
    height: 30px;
    margin: 10px 0;

    .confidence-details-very {
      background-color: @confidence-very;
    }

    .confidence-details-fairly {
      background-color: @confidence-fairly;
    }

    .confidence-details-slightly {
      background-color:  @confidence-slightly;
    }
  }

  .confidence-details-table {
    margin: 10px 0;
    
    .ant-table-thead .ant-table-cell {
      background-color: @primary-4;
      font-weight: bold;
      text-transform: uppercase;
      color: @primary-9;
    }

    .ant-table-thead .ant-table-cell:first-child {
      width: 30px;
    }

    .block-color {
      width: 20px;
      height: 20px;

      &.very {
        background-color: @confidence-very;
      }

      &.fairly {
        background-color: @confidence-fairly;
      }

      &.slightly {
        background-color: @confidence-slightly;
      }
    }

    .level {
      font-weight: bold;

      &.very {
        color: @confidence-very;
      }

      &.fairly {
        color: @confidence-fairly;
      }

      &.slightly {
        color: @confidence-slightly;
      }
    }

    .stats {
      &.very {
        color: @confidence-very;
      }

      &.fairly {
        color: @confidence-fairly;
      }

      &.slightly {
        color: @confidence-slightly;
      }

    }
  }
}

