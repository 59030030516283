@import '../../../global.less';

.timestamp-editor-display {
  border: 1px solid transparent;
  padding: 2px 5px;

  &:hover {
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid #cecece;
  }
}

.timestamp-editor-content {
  .timestamp-editor-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h3, h4 {
      margin: 0;
    }

    .timestamp-error {
      cursor: help;
      background-color: @red-6;
      padding: 0 8px;
      height: 18px;
      font-size: 11px;
      font-weight: bold;
      color: #fff; 
    }
  }

  .timestamp-editor-box {
    display: flex;
    border: 1px solid #cecece;
    margin-bottom: 16px;

    & > div {
      flex: 1;

      .form-time-label {
        position: relative;
        display: inline-block;
        width: 100%;

        &:before {
          position: absolute;
          content: attr(data-time-separator);
          top: 6px;
          bottom: 0;
          height: 100%;
          left: -12px;
          font-size: 24px;
          font-weight: bold;
          color: #aab7c4;
        }

        &:after {
          position: absolute;
          content: attr(data-time-label);
          left: 0;
          right: 15px;
          bottom: 1px;
          font-size: 11px;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
          color: #aab7c4;
        }

        .form-time-field {
          padding: 5px 8px 13px 8px;
          border: none;
          font-size: 22px;
          text-align: center;
        }
      }
    }
  } 
}
