@import '~antd/es/style/themes/default.less';

.package-list-root {
  .ant-col {
    padding: 0 @padding-sm;

    &:nth-child(3n + 1) { padding-left: 0; }
    &:nth-child(3n) { padding-right: 0; }
  }

  .package-container {
    border: @border-width-base @border-style-base @border-color-base;
    padding: @padding-md;
    margin-bottom: @margin-md;
    background-color: #fff;

    .row {
      border-top: @border-width-base @border-style-base @border-color-base;
      padding: @padding-sm 0;
      margin-top: @padding-sm;
    }

    h4 {
      margin-bottom: 0;
    }

    .subtext {
      text-transform: uppercase;
    }

    .description {
      height: 50px;
    }

    .pricing {
      height: 80px;
      font-size: 120%;
      font-weight: bold;
    }
  }
}
