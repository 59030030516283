@import '~antd/es/style/themes/default.less';

.upload-progress-container {
  padding-top: 25px;

  .upload-progress-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: @padding-md;

    h4 {
      margin-bottom: 0;
    }
  }

  .out-of-balance {
    margin-bottom: @margin-md;
    font-weight: bold;
  }

  .upload-progress-content {
    background-color: #fff;

    .upload-progress-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: @padding-xs @padding-md;
      border-bottom: @border-width-base @border-style-base @border-color-base;

      &:last-child {
        border-bottom: none;
      }
      
      .upload-progress-item-info {
        flex: 1;

        .upload-progress-item-info-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 30px;

          .upload-error {
            color: @error-color;
          }

          .upload-progress-item-info-checkbox {
            flex: 0 0 50px;
          }

          .upload-progress-item-info-name {
            flex: 1;
          }

          .upload-progress-item-info-length {
            flex: 0;
          }
        }

        .upload-progress-item-progress {
          height: 25px;
        }
      }

      .upload-progress-item-language,
      .upload-progress-item-num-speakers {
        padding: 0 15px;
      }
    }
  }

  @media (min-width: @screen-xl) {
    padding-top: 0;
    padding-left: 15px;
  }
}
