@import '../../../global.less';

.speaker(@name; @attr; @index: 1) when (@index =< length(@speaker-colors)) {
  .speaker(@name; @attr; (@index + 1));
  .@{name}.speaker-@{index} {
    @{attr}: extract(@speaker-colors, @index);
  }
}

.speaker-stats-root {
  min-width: 400px;

  .speaker-stats-chart {
    display: flex;
    width: 100%;
    height: 30px;
    margin: 10px 0;

    .speaker(speaker-stats-chart-portion; background-color);
  }

  .speaker-stats-details-table {
    margin: 10px 0;
    
    .ant-table-thead .ant-table-cell {
      background-color: @primary-4;
      font-weight: bold;
      text-transform: uppercase;
      color: @primary-9;
    }

    .ant-table-thead .ant-table-cell:first-child {
      width: 30px;
    }

    .block-color {
      width: 40px;
      height: 40px;
    }
    .speaker(block-color, background-color);

    .info {
    }
    .speaker(info, color);
  }
}


