@import '~antd/es/style/themes/default.less';
@import '../../global.less';

.editor-root {
  padding: 10px 50px;

  .editor-header-container {
    .editor-header-filename {
    }

    .editor-header-back {
      padding-right: @padding-sm;
    }
  }

  .editor-menu-container {
    display: flex;
    align-items: center;
    padding: @padding-sm 0;

    .editor-menu-item {
      cursor: pointer;
      padding: 2px 5px;

      &:hover {
        background-color: #dedede;
      }

      &:first-child {
        margin-left: -5px;
      }
    }

    .editor-header-last-update {
      margin-left: 20px;
      font-size: 80%;
      color: #a3a3a3;
      font-style: italic;
    }
  }

  .editor-toolbar-container {

  }

  .editor-player-container {

  }

  .editor-content-container {
    padding: 20px 0;
    overflow-y: auto;
  }

  &.with-confidence {
    .teditor-segment .teditor-word {
      &.very-confident {
        color: @confidence-very;
      }
      &.fairly-confident {
        color: @confidence-fairly;
      }
      &.slightly-confident {
        color: @confidence-slightly;
      }
    }
  }
}

.teditor-root {
  padding: 20px 50px;

  .teditor-segment {
    padding: 0 0 @padding-md 0;

    .teditor-segment-left {
      padding-right: @padding-md;
      user-select: none;

      @media (min-width: @screen-lg) {
        flex-basis: 300px;
        max-width: 300px;
      }

      .teditor-segment-control {
        padding: 0 @padding-sm;

        .anticon {
          cursor: pointer;
          padding: 0 2px;
        }
      }

      hr {
        border: 1px solid #dedede;
        margin: 5px 0;
      }
    }

    .teditor-segment-content {
      padding-top: 25px;

      @media (min-width: @screen-lg) {
        flex-basis: calc(100% - 400px);
        max-width: calc(100% - 400px);
      }
    }

    .teditor-segment-right {
      border-left: 1px solid rgb(218, 230, 238);
      padding: @padding-xs @padding-md;
      user-select: none;

      & > * {
        padding: 0 2px;
      }

      &.segment-complete {
        border-left: 3px solid rgb(25, 147, 66);
        padding-left: (@padding-md - 2);
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: rgb(25, 147, 66);
        border-color: rgb(25, 147, 66);
      }

      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox.ant-checkbox-checked:after {
        border-color: rgb(25, 147, 66);
      }

      @media (min-width: @screen-lg) {
        flex-basis: 100px;
        max-width: 100px;
      }
    }

    .teditor-word {
      position: relative;
      padding: 0;
      line-height: 28px;

      &.strikethrough {
        color: @strikethrough-color;
        background-color: @strikethrough-background;
        text-decoration: line-through;
        text-decoration-color: @strikethrough-color;
      }

      &.highlight {
        text-decoration: none;
      }
    }
  }

  .teditor-leaf {
    display: inline-block;
  }

}

// @media (min-width: @screen-lg) {
//   .editor-root .teditor-root .teditor-segment {
//     .teditor-segment-left {
//       width: 300px;
//     }

//     .teditor-segment-content {
//       width: calc(100% - 300px);
//     }
//   }
// }
