@yellow: rgb(233, 199, 123);

.segment-note-button {
  position: relative;
  cursor: pointer;
  color: @yellow;
  transition: all 0.3s ease-out;

  &:hover {
    background-color: @yellow;
    color: #fff;
  }
}

.segment-note-popup {
  width: 220px;

  h3 {
    color: @yellow;
  }
}
