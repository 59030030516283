@import '~antd/es/style/themes/default.less';

.upload-root {
  .upload-dnd-container {
    height: 150px;
    margin-bottom: 70px;

    @media (min-width: @screen-xl) {
      height: 500px;
      margin-bottom: 0;
    }

    & > div {
      height: 100%;
    }
  }

  .upload-note {
    margin-top: @padding-sm;
    font-style: italic;
    color: #adadad;
  }
}
