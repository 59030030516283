@import '../../../global.less';

.audio-player-root {
  position: relative;
  padding: @padding-xs 0;

  .current-pos {
    position: absolute;
    top: 7px;
    left: -55px;
    font-size: 13px;
  }

  .duration {
    position: absolute;
    top: 7px;
    right: -55px;
    font-size: 13px;
  }

  .seek-bar-container {
    position: relative;
    background-color: #dedede;
    border: 1px solid #dedede;
    height: 15px;
    width: 100%;

    .seek-bar-overlay {
      cursor: pointer;
      position: absolute;
      background: transaprent;
      width: 100%;
      height: 15px;
    }

    .seek-bar-progress {
      background-color: @primary-color;
      height: 100%;
      width: 0;
    }

    .seek-bar-handle {
      position: absolute;
      background-color: @primary-color;
      width: 2px;
      height: 23px;
      top: 0;
      left: 0;
    }
  }
}
