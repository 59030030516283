@import '~antd/es/style/themes/default.less';

.select-lang-overlay > * {
  position: relative;
  top: 16px;
}

.select-lang-dropdown {
  cursor: pointer;
  color: #fff;

  > span {
    font-size: 16px !important;
    transform: none !important;
  }
}
