.appsumo-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(253, 253, 253);

  .appsumo-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 0px #0000001a;
    padding: 32px 24px;
    box-sizing: border-box;
    height: 100%;

    @media (min-width: 768px) {
      width: 350px;
    }

    .firebaseui-idp-button {
      border-radius: 0;
    }
  }

  .appsumo-image {
    flex: 1;
    display: none;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
      display: flex;
    }
  }
}
