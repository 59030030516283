@import '~antd/es/style/themes/default.less';

.side-menu-main {
  background-color: #fff;
  flex: 1 1 0%;
  border-bottom: 1px solid @border-color-split;
  padding-top: @padding-xs;
  overflow: hidden auto;
  width: 100%;
}

.side-menu-links {
  width: 100%;
  overflow: hidden auto;
}

.side-menu-main .ant-menu-inline,
.side-menu-links .ant-menu-inline {
  width: calc(100% + 1px);
}

.root .ant-layout-sider.fixed-sider .ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 16px;
}
