@import '~antd/es/style/themes/default.less';

.recent-root {
  .ant-table-tbody {
    & > tr.ant-table-row-level-0:hover > td {
      cursor: pointer;
      background: unset;
    }

    & > tr.row-selected > td,
    & > tr.ant-table-row-level-0.row-selected:hover > td {
      background: @background-color-base;
    }
  }
}

